import React, { Fragment, useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Formik, Form } from "formik";

import Layout from "layout";
import Container from "layout/Container";
import Section from "elements/Section";
import Message from "elements/Message";
import ActionButtons from "elements/ActionButtons";

import { isBrowser, isObjectEmpty } from "../../services/general";
import { generateFormField } from "elements/Form/services/form";

import { NutritionistContext } from "./context";

import { generateInitialValues } from "../../services/context";
import { useSectionFormFields } from "./context/hooks/useSectionFormFields";
import { heightAndWeightOptions } from "./utils/heightAndWeightOptions";

// import { ConsultationContext } from "../ConsultationContext/ConsultationContext";

const DietarySreening = ({ pageContext }) => {
	const { nutritionistState, nutritionistDispatch } = useContext(
		NutritionistContext
	);

	let { progress, numberOfPages } = pageContext;

	let { sectionFormFields, validationSchema } = useSectionFormFields({
		formFields: pageContext.formFields,
	});

	sectionFormFields = sectionFormFields.sort((a, b) => a.order - b.order);

	const handleSubmit = async (values) => {
		await nutritionistDispatch({
			type: "SAVE_FORM",
			payload: {
				...values,
			},
		});

		navigate(pageContext.nextPath);
	};

	useEffect(() => {
		if (isBrowser()) {
			nutritionistDispatch({ type: "GET_CONTEXT_FROM_SESSION" });
		}
	}, []);

	let initialValues = {
		...generateInitialValues({
			fields: pageContext.formFields,
		}),
		...nutritionistState,
	};

	const handleFollowUpOnChange = ({
		field,
		setFieldValue,
		e,
		formFields,
		values,
	}) => {
		//resetField
		for (let i = 0; i < field.followUpQuestions.length; i++) {
			let followUpField = formFields.filter(
				(f) => f.name === field.followUpQuestionsName[i]
			)[0];

			if (followUpField.type === "select") {
				setFieldValue(field.followUpQuestionsName[i], {
					value: undefined,
					label: undefined,
				});
			} else if (!!followUpField.inverseReferenceAnswer) {
				setFieldValue(
					field.followUpQuestionsName[i],
					values[field.followUpQuestionsName[i]]
				);
			} else if (field.type === "checkbox") {
				setFieldValue(
					field.followUpQuestionsName[i],
					values[field.followUpQuestionsName[i]]
				);
			} else {
				setFieldValue(field.followUpQuestionsName[i], "");
			}
		}
	};

	return (
		<Layout
			title={pageContext?.module?.title}
			subtitle={pageContext?.module?.subtitle}
			seoTitle={pageContext?.module?.seoTitle}
			pageContext={pageContext}
			isPrivate
			progress={(progress / numberOfPages) * 100}
			numberOfPages={numberOfPages}
			polygonStyle="forms"
			isForms
		>
			<Container isCentered mobile={12} tablet={10}>
				<Formik
					initialValues={{ ...initialValues }}
					onSubmit={handleSubmit}
					enableReinitialize={true}
					validationSchema={validationSchema}
				>
					{({ values, setFieldValue, errors }) => (
						<Form>
							{sectionFormFields.map((section) => {
								const lastIndex = section?.fields.length;
								return (
									<Section
										title={section?.section}
										subtitle={section?.subtitle || ""}
									>
										{section?.message && (
											<Message color="light">{section?.message}</Message>
										)}
										<ol className="ml-1">
											{section?.fields.map((field, index) => {
												if (!field?.referenceAnswer) {
													if (field?.followUpQuestions.length)
														field.onChange = (e) => {
															handleFollowUpOnChange({
																field,
																formFields: section?.fields,
																setFieldValue,
																e,
																values,
															});
														};

													return (
														<Fragment>
															<li className="">
																{generateFormField({
																	formFields: section?.fields,
																	formField: {
																		...field,
																		...(field.name === "height" && {
																			options: heightAndWeightOptions.height,
																		}),
																		...(field.name === "weight" && {
																			options: heightAndWeightOptions.weight,
																		}),
																	},
																	values,
																	setFieldValue,
																	context: {
																		state: nutritionistState,
																		dispatch: nutritionistDispatch,
																	},
																})}
																{!!field?.addDividerAfterField && (
																	<hr className="has-background-light" />
																)}
															</li>
														</Fragment>
													);
												}
												return null;
											})}
										</ol>
									</Section>
								);
							})}

							{!isObjectEmpty(errors) && (
								<Message>
									You may have missed some required fields. Please scan through
									the form and check if your information is complete.
								</Message>
							)}

							<ActionButtons
								back={{
									link:
										pageContext.backPath === "/"
											? "/services"
											: pageContext?.backPath,
								}}
								submit={{ label: `Next: ${pageContext?.nextPathTitle}` }}
							/>
						</Form>
					)}
				</Formik>
			</Container>
		</Layout>
	);
};

export default DietarySreening;
